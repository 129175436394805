<template>
  <b-overlay :show="isLoadingControlAccessData || isLoadingFormData" rounded class="">
    <ValidationObserver
      ref="formRequestPropertyAccess"
      tag="form"
    >
      <b-form @submit.prevent="sendDataForm">

        <!-- Tipo de Accesso -->
        <div class="border rounded p-1 mb-1">
          <h5>{{$t('Access data')}}</h5>
          <b-row>
		
            <b-col md="3">
              <ValidationProvider :name="$t('Reason')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Reason')}}* </label>
                  <b-form-select
                    v-model="accessData.reason"
                    :state="errors.length > 0 ? false : null"
                    @input="manageAccessTypeChange"
                  >
                    <option :value="null" disabled>{{$t('Select an option')}}</option>
                    <option
                      v-for="(reason, index) in reasons"
                      :key="index"
                      :value="reason.id"
                    >
                      {{ $i18n.locale === 'en' ? reason.englishName : reason.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3" class="mt-1" v-if="allowFullDay && showForms && !!accessData.reason">
              <ValidationProvider :name="$t('Access type')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <b-form-checkbox
                    :value="true"
                    :unchecked-value="false"
                    v-model="accessData.fullDay"
                    @input="changeTime"
                    class="mt-1"
                  >
                    {{ $t("24 hrs. access?") }}
                  </b-form-checkbox>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row v-if="showForms && !!accessData.reason">

            <b-col md="3">
              <ValidationProvider :name="$t('AccessDate')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('AccessDate')}}* </label>
                  <flat-pickr
                    :config="dateInConfig"
                    :class="`form-control white-background ${errors.length > 0 ? 'is-invalid' : ''}`"
                    v-model="accessData.dateIn"
                    @input="dateInChanged"
                    :placeholder="$t('pick date')"
                  />
                    <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3" class="border-right">
              <ValidationProvider :name="$t('timeIn')" :rules="accessData.fullDay ? '' : 'required'">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('timeIn')}}* </label>

                  <TimePickerDropdown
                    :disable="accessData.fullDay"
                    v-model="accessData.timeIn"
                    type="timeIn"
                    :minHour="minHour"
                    :maxHour="maxHour"
                    :step="15"
                    ref="timeInTimePickerDropdown"
                    :errors="errors.length > 0"
                    :class="`white-background ${errors.length > 0 ? 'is-invalid' : ''}`"
                    @get-time="setTime"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="" v-if="allowsDateRange">
              <ValidationProvider :name="$t('ExitDate')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('ExitDate')}}* </label>
                  <flat-pickr
                    v-if="!showLoadingDateout"
                    :config="dateOutConfig"
                    :class="`form-control white-background ${errors.length > 0 ? 'is-invalid' : ''}`"
                    v-model="accessData.dateOut"
                    :placeholder="$t('pick date')"
                  />
                  <div v-else class="text-center">
                    <b-spinner class="mt-1" small/>
                  </div>
                    <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider :name="$t('timeOut')" :rules="accessData.fullDay ? '' : 'required'">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('timeOut')}}* </label>

                  <TimePickerDropdown
                    :disable="accessData.fullDay"
                    v-model="accessData.timeOut"
                    type="timeOut"
                    :minHour="minHour"
                    :maxHour="maxHour"
                    :step="15"
                    ref="timeOutTimePickerDropdown"
                    :errors="errors.length > 0"
                    :class="`white-background ${errors.length > 0 ? 'is-invalid' : ''}`"
                    @get-time="setTime"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider :name="$t('Comments')" rules="max:500">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Comments')}} </label>
                  <b-form-textarea
                    v-model="accessData.notes"
                    :placeholder="$t('Write comments')"
                    rows="2"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <div v-else-if="!showForms" class="text-center">
            <b-spinner/>
          </div>
        </div>

        <!-- Datos del invitado -->
        <ValidationObserver
          ref="formRequestPropertyAccessGuests"
          tag="form"
          :disabled="true"
        >
          <div class="border rounded p-1" v-if="!!accessData.reason">
            <h5>{{$t('Guests data')}} </h5>
            <b-row>
              <b-col md="">
                <ValidationProvider :name="$t('name')" rules="required">
                  <b-form-group slot-scope="{ errors }">
                    <label> {{ $t('name')+'*'}} </label>
                    <b-form-input
                      :placeholder="$t('name')"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="">
                <ValidationProvider :name="$t('lastname')" rules="required">
                  <b-form-group slot-scope="{ errors }">
                    <label> {{ $t('lastname')}}* </label>
                    <b-form-input
                      :placeholder="$t('lastname')"
                      v-model="lastname"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="">
                <ValidationProvider :name="$t('Email')" rules="email">
                  <b-form-group slot-scope="{ errors }">
                    <label> {{ $t('Email')}} </label>
                    <b-form-input
                      :placeholder="$t('Email')"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="" v-if="dontRequireGuestType != true">
                <ValidationProvider :name="$t('guestType')" rules="required">
                  <b-form-group slot-scope="{ errors }">
                    <label> {{ $t('guestType')+'*'}} </label>
                    <b-form-select
                      v-model="type"
                      :disabled="accessData.reason && !!dontRequireGuestType"
                      :state="errors.length > 0 ? false : null"
                    >
                      <option :value="null">{{$t('Select an option')}}</option>
                      <option
                        v-for="(type, index) in typeGuests"
                        :key="index"
                        :value="type.value"
                      >
                        {{ type.text }}
                      </option>
                    </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="">
                <b-button
                  variant="primary"
                  class="float-right"
                  @click="addGuest"
                  > <feather-icon icon="PlusIcon" size="16" /> {{$t('Add')}}
                  <!-- :disabled="invalid" -->
                </b-button>
              </b-col>
            </b-row>

            <!-- Tabla de Invitados -->
            <div class="mt-1 border-top pt-1" v-if="accessData.guestData.length">
              <b-table
                small
                responsive
                :fields="guestsDataFields"
                :items="accessData.guestData"
                sticky-header="150px"
                :busy.sync="isLoadingControlAccessData"
              >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                  <strong> {{$t('loading')}}... </strong>
                </div>
              </template>
                <template #cell(actions)="row">
                  <b-button
                    variant="danger"
                    :disabled="accessData.guestData.length > 15"
                    class="m-0"
                    size="sm"
                    @click="deleteGuest(row.item)"
                  > <feather-icon icon="TrashIcon" size="12" />
                  </b-button>
                </template>
              </b-table>
            </div>
          </div>
        </ValidationObserver>

        <b-button
          variant="primary"
          :disabled="isSavingControlAccessData || !accessData.reason"
          class="float-right mt-2"
          type="submit"
        > <b-spinner v-if="isSavingControlAccessData" small/>
          <feather-icon icon="SaveIcon" size="16" v-else/> {{$t('Save')}}
        </b-button>
      </b-form>
    </ValidationObserver>
    <b-card>
      <b-card-text class="text-center">
        <!-- {{$t('control access disclaimer')}} -->
      </b-card-text>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { currentDate } from '@/helpers/market'
import { stringAleatorio, showAlertMessage } from '@/helpers/helpers'
import { accesTypes } from "@/data/data"
import * as moment from "moment"
import { utils } from "@/modules/owners/mixins/utils"
import flatPickr from "vue-flatpickr-component"
import TimePickerDropdown from '@/modules/myProperty/components/TimePickerDropdown'

export default {
	mixins: [utils],
  async created(){
    await this.init()
  },
  props: {
    accessData: {
      type: Object,
      default: null,
      required: true
    },
    originalAccessData: {
      type: Object,
      default: null,
      required: false
    },
    isLoadingControlAccessData: {
      type: Boolean,
      default: false,
      required: false
    },
    isSavingControlAccessData: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      isLoadingFormData: null,
      showForms: true,
      name: null,
      lastname: null,
      email: null,
      type: null,
      today: currentDate(),
      reason: null,
      enabledDates: [],
      maxDaysAllowed: null,
      allowsDateRange:null,
      showLoadingDateout: false,
      guestsDataFields: [
        { key: 'name', label: this.$t('name'), },
        { key: 'lastname', label: this.$t('lastname'), },
        { key: 'email', label: this.$t('Email'), },
        { key: 'type', label: this.$t('guestType'), formatter: value=> {return this.typeGuests?.find(guest=> guest.value == value)?.text || value}},
        { key: 'actions', label: this.$t('Actions'), },
      ],
      typeGuests: [
        {value: "1", text: this.$t('Adult')},
        {value: "2", text: this.$t('Children')}
      ],
      maxGuests: 5,
      maxDays: 5,
      allowFullDay: false,
      dateInConfig: {
        minDate: moment().format("YYYY-MM-DD")
      },
      dateOutConfig: {
        minDate: moment().format("YYYY-MM-DD"),
      },
      minHour: null,
      maxHour: null,
      dontRequireGuestType: null
    }
  },
  components: {
    flatPickr,
    TimePickerDropdown
  },
  computed:{
    ...mapState('auth',['user', 'myContracts']),
    ...mapState('myProperty',['bookings', 'controlAccessType']),
    checkIfFilledData(){
      return (!this.name || !this.lastname || !this.type) || ( this.maxGuests ? this.accessData?.guestData?.length > this.maxGuests : this.accessData?.guestData?.length > 0 )
    },
    checkIffullDayAccess(){
      if (accessData.fullDay) {
        this.accessData.timeIn = null
        this.accessData.timeOut = null
      }
      return accessData.fullDay
    },
    canSaveRegister(){
      const { reason, dateIn, dateOut, fullDay, timeIn, timeOut, guestData } = this.accessData
      const hasGuests = guestData?.length > 0
      const maxDataAllowed = this.maxGuests ? guestData?.length <= this.maxGuests : true
      const timeValid = this.isOutOfPool ? (!!timeIn && !!timeOut) : (fullDay || (!fullDay && !!timeIn && !!timeOut))
      return (!!reason && !!dateIn && (this.isOutOfPool ? !!dateOut : true ) && (hasGuests && maxDataAllowed) && timeValid)
    },
    isOutOfPool(){
      return this.myContracts?.contractSelected?.membershipcode === 'CLASSIC'
    },
    reasons(){
      return this.controlAccessType
    }
  },
	methods:{
		...mapActions('myProperty', ['saveControlAccess', 'fetchControlAccessType']),
		...mapMutations('myProperty', ['setControlAccessType']),
    async init(){
      this.isLoadingFormData = true
      const maxGuestsAllowed = await this.getParameters(10)
      const maxDaysAllowed = await this.getParameters(11)
			const reasons = await this.fetchControlAccessType({membershipId: this.myContracts.contractSelected.membershipid})
			if (reasons) {
        this.setControlAccessType(reasons)
        await this.setAccessRules()
      } else {
        showAlertMessage(this.$t('no reasons configured'), 'InfoIcon', `${this.$t('try again')}`, 'warning', 4000, 'bottom-right')
        this.$router.push({name: 'maintenance-request', params: { id: this.accessData.id } })
        await this.pushData('onClick', 'goToShowMaintenanceDataRequest', 'goToShowMaintenanceDataRequest', this.$route.name)
      }
      // this.setActiveDates()
      if (maxGuestsAllowed) this.maxGuests = this.isOutOfPool ? false : parseInt(maxGuestsAllowed?.value)
      if (maxDaysAllowed) this.maxDays = this.isOutOfPool ? false : parseInt(maxDaysAllowed?.value)


      this.isLoadingFormData = false
    },
    async manageAccessTypeChange(){
      if (this.accessData.reason){
        const selectedReason = this.controlAccessType.find(reason => reason.id == this.accessData.reason)?.rules
        if (selectedReason){
          this.accessData.timeIn = null
          this.accessData.timeOut = null
          this.accessData.dateIn = null
          this.accessData.dateOut = null
          // this.setActiveDates()
          await this.setAccessRules()
        }
      }
    },
    async setAccessRules(){
      this.showForms = false
      if (this.accessData.reason){
        const selectedReason = this.controlAccessType.find(reason => reason.id == this.accessData.reason)?.rules
        if (selectedReason){
          if (!!selectedReason?.periodDays) {
            this.dateOutConfig.maxDate = moment().add(selectedReason?.periodDays, 'days').format("YYYY-MM-DD")
          }

          this.maxDaysAllowed = selectedReason?.periodDays
          this.allowsDateRange = selectedReason?.periodDays > 1
          this.maxGuests = selectedReason?.maxVisitors
          this.allowFullDay = selectedReason?.allowAllDayAccess
          if (!selectedReason?.allowAllDayAccess) this.accessData.fullDay = false
          this.minHour = selectedReason?.minHour
          this.maxHour = selectedReason?.maxHour
          this.dontRequireGuestType = !selectedReason?.requireGuestType
          if (!selectedReason?.requireGuestType) {
            this.type = "1"
            if (this.accessData.guestData.length > 0){
              const hasChilds = this.accessData.guestData.filter(guest => guest.type == '2')
              if (hasChilds.length > 0) this.accessData.guestData = this.accessData.guestData.filter(guest => guest.type != '2')
            }
          }
        }
      }

      else {
        this.dateOutConfig.maxDate = null
        delete this.dateOutConfig.maxDate
        this.maxDaysAllowed = null
        this.allowsDateRange = null
        this.maxGuests = null
        this.allowFullDay = null
        this.minHour = null
        this.maxHour = null
        this.dontRequireGuestType = null
      }

      await this.makeTime()

      this.showForms = true
    },
    async validateForm(){
      const formIsValid = await this.$refs.formRequestPropertyAccess.validate()
      const hasGuests = this.accessData?.guestData?.length > 0
      const timeIsValid = this.validTime()
      if (!hasGuests) {
        return showAlertMessage(this.$t('Cannot save register'), 'InfoIcon', `${this.$t('you must have guests')}`, 'warning', 4000, 'bottom-right')
      }
      return formIsValid && timeIsValid && hasGuests
    },
    async sendDataForm(){
      const isValid = await this.validateForm()
      
      if (isValid) this.$emit('save-register', this.accessData)
    },
    async dateInChanged(){
      this.showLoadingDateout = true
      this.dateOutConfig.minDate = !!this.accessData.dateIn ? moment(this.accessData.dateIn).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
      if (this.allowsDateRange){
        if (!!this.maxDaysAllowed) {
          this.dateOutConfig.maxDate = moment(this.accessData.dateIn).add(this.maxDaysAllowed - 1, 'days').format("YYYY-MM-DD")
        }
      }
			const dateInvalid = moment(this.accessData.dateIn).isAfter(this.accessData.dateOut, 'day')
      if (dateInvalid) this.accessData.dateOut = null
      await this.makeTime()
      this.showLoadingDateout = false
    },
    setTime(payload){
      if (payload.type == 'timeIn') this.accessData.timeIn = payload.finalTime
      if (payload.type == 'timeOut') this.accessData.timeOut = payload.finalTime
    },
    setActiveDates(){
			if (this.isOutOfPool == false) {
				const enabledDates = []
				this.bookings.forEach(booking => {
					enabledDates.push({
						from: moment(booking.datein).format("YYYY-MM-DD"),
						to: moment(booking.dateout).format("YYYY-MM-DD")
					})
				})
				if (enabledDates.length > 0) this.dateInConfig.enable = enabledDates
			}
    },
    validTime(){
      if (this.accessData.fullDay) return true
      else {
        const timeIn = this.accessData?.timeIn?.split(':')
        const timeOut = this.accessData?.timeOut?.split(':')
        if (timeIn?.length && timeOut?.length){
          const timeInHH = timeIn[0]
          const timeInMM = timeIn[1]
          const timeInSS = timeIn[2]

          const timeOutHH = timeOut[0]
          const timeOutMM = timeOut[1]
          const timeOutSS = timeOut[2]

          const sameHour = (timeInHH == timeOutHH)
          const hoursAfter = (timeInHH > timeOutHH)
          const minutesAfter = (timeInMM > timeOutMM)
          const sameMinutes = (timeInMM == timeOutMM)

          const timeInvalid = hoursAfter || (sameHour && minutesAfter)
          const sameTime = (sameHour && sameMinutes)

          if (timeInvalid) {
            this.accessData.timeOut = null
            this.$refs.timeOutTimePickerDropdown.resetTime()
            showAlertMessage(this.$t('Cannot save register'), 'InfoIcon', `${this.$t('invalid timeout')}`, 'warning', 4000, 'bottom-right')
          }

          if (sameTime) {
            this.accessData.timeOut = null
            this.$refs.timeOutTimePickerDropdown.resetTime()
            showAlertMessage(this.$t('Cannot save register'), 'InfoIcon', `${this.$t('same timeout')}`, 'warning', 4000, 'bottom-right')
          }

          return !timeInvalid && !sameTime
        }
      }

    },
    changeTime(){
      if (this.accessData.fullDay) {
        this.accessData.timeOut = null
        this.accessData.timeIn = null
      } else {
        this.accessData.timeIn = structuredClone(this.originalAccessData.timeIn)
        this.accessData.timeOut = structuredClone(this.originalAccessData.timeOut)
      }
    },
    async addGuest(){
      const isValid = await this.$refs.formRequestPropertyAccessGuests.validate()

      if (isValid) {
        if (this.name && this.lastname && this.type ) this.accessData.guestData.push({
          name: this.name,
          lastname: this.lastname,
          email: this.email,
          type: !this.dontRequireGuestType ? this.type : '1',
          key: stringAleatorio()
        })

        this.name = null
        this.lastname = null
        this.email = null
        this.type = !this.dontRequireGuestType ? null : '1'

        await this.$refs.formRequestPropertyAccessGuests.reset()
      }
    },
    deleteGuest(item){
      this.accessData.guestData = this.accessData.guestData.filter(guest=> item.id ? guest.id != item.id : guest.key != item.key)
    },
    async makeTime(){
      const myPromise = await new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log('making time')
          resolve()
        }, 600);
      })
      return myPromise
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.white-background{
  background-color: white !important;
}
</style>