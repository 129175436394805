<template>
  <div>
    <b-input-group :class="errors ? 'border-red' : ''">
      <b-form-select
        v-model="timeHours"
        @change="selectedTime()"
        :disabled="disable"
      >
        <option :value="null" class="disabled-option" disabled>Hrs</option>
        <option
          v-for="hour in hours"
          :key="hour.value"
          :value="hour.value"
          :disabled="!!hour.disabled"
          :class="!!hour.disabled ? 'disabled-option' : ''"
        >{{hour.text}}</option>
      </b-form-select>

      <b-form-select
        v-model="timeMinutes"
        @change="selectedTime()"
        :disabled="!!disable"
      >
        <option :value="null" class="disabled-option" disabled>Min</option>
        <option
          v-for="minute in minutes"
          :key="minute.value"
          :value="minute.value"
          :disabled="!!minute.disabled"
          :class="!!minute.disabled ? 'disabled-option' : ''"
        >{{minute.text}}</option>
      </b-form-select>

      <b-form-select
        v-if="format == '12'"
        v-model="timeSeason"
        @change="selectedTime()"
        :disabled="disable"
      >
        <option :value="null" class="disabled-option" disabled>AM/PM</option>
        <option
          v-for="season in seasonOptions"
          :key="season.value"
          :value="season.value"
          :disabled="!!season.disabled"
          :class="!!season.disabled ? 'disabled-option' : ''"
        >{{season.text}}</option>
      </b-form-select>
    </b-input-group>
  </div>
</template>

<script>
import { formatTime } from '@/helpers/helpers'

export default {
  created(){
    this.transformDateToDropdowns()
    if (this.minSeason || this.maxSeason) this.timeSeason = this.minSeason || this.maxSeason
    this.validateTime()
  },
  props: {
    value: {
      type: [String, Boolean],
      required: false
    },
    type: {
      type: String,
      required: false
    },
    format: {
      type: String,
      default: '12',
      required: false
    },
    errors: {
      type: Boolean,
      default: false,
      required: false
    },
    minHour: {
      type: [String, Boolean],
      required: false
    },
    maxHour: {
      type: [String, Boolean],
      required: false
    },
    disable: {
      type: Boolean,
      default: false,
      required: false
    },
    step: {
      type: Number,
      default: 1,
      required: false
    },
    origin: {
      type:[Object,Boolean],
      default: false,
      required: false
    }
  },
  watch: {
    value(){
      this.transformDateToDropdowns()
    }
  },
  data() {
    return {
      // minutes: ['00', 15, 30, 45],
      seasonOptions: [
        {text: 'AM', value: 'AM', disabled: false},
        {text: 'PM', value: 'PM', disabled: false}
      ],

      timeUsed:this.value,
      timeHours: null,
      timeMinutes: null,
      timeSeason: 'AM',

      minHours: null,
      minMinutes: null,
      minSeason: null,

      maxHours: null,
      maxMinutes: null,
      maxSeason: null,
    }
  },
  computed:{
    minutes(){
      const minutes = []
      let disableTime = false

      for (let min = 0; min < 60; min++) {
        if (this.minMinutes > 0 && this.minSeason === this.timeSeason ) disableTime = (min < this.minMinutes || 0)
        if (this.maxMinutes > 0 && this.maxSeason === this.timeSeason ) disableTime = (min > this.maxMinutes || 0)

        minutes.push({
          text:`0${min}`.slice(-2),
          value:`0${min}`.slice(-2),
          disabled: disableTime
        })
        min = min + parseInt(this.step) - 1
      }
      return minutes
    },
    hours(){
      const hours = []
      const maxHours = this.format === '12' ? 13 : 25
      for (let hrs = this.format === '12' ? 1 : 0; hrs < maxHours; hrs++) {
        let disableTime = false
        if (!!this.minHours && this.minSeason === this.timeSeason ) disableTime = (hrs < this.minHours || 0)
        if (!!this.maxHours && this.maxSeason === this.timeSeason ) disableTime = (hrs > this.maxHours || 0)

        if (!disableTime) hours.push({
          text:`0${hrs}`.slice(-2),
          value:`0${hrs}`.slice(-2),
          disabled: disableTime
        })
      }
      return hours
    }
  },
	methods:{
    transformDateToDropdowns(){
      if (Boolean(this.value)) {
        const timeSplited = formatTime({time: this.value, format:"HH:mm:ss", toFormat: this.format === '12' ? "hh:mm:A" : "HH:mm"}).split(':')
        this.timeHours = timeSplited[0]
        this.timeMinutes = timeSplited[1]
        if (timeSplited.length > 2) this.timeSeason = timeSplited[2]
        this.selectedTime()

      } else this.resetTime()
      if (Boolean(this.minHour)) {
        const minTimeSplited = formatTime({time: this.minHour, format:"HH:mm:ss", toFormat: this.format === '12' ? "hh:mm:A" : "HH:mm"}).split(':')
        this.minHours = minTimeSplited[0]
        this.minMinutes = minTimeSplited[1]
        if (minTimeSplited.length > 2) this.minSeason = minTimeSplited[2]
        this.validateTime()
        // this.seasonOptions.find(season => season.value == minTimeSplited[2]).disabled = true
      }
      if (Boolean(this.maxHour)) {
        const maxTimeSplited = formatTime({time: this.maxHour, format:"HH:mm:ss", toFormat: this.format === '12' ? "hh:mm:A" : "HH:mm"}).split(':')
        this.maxHours = maxTimeSplited[0]
        this.maxMinutes = maxTimeSplited[1]
        if (maxTimeSplited.length > 2) this.maxSeason = maxTimeSplited[2]
        this.validateTime()
        // this.seasonOptions.find(season => season.value == maxTimeSplited[2]).disabled = true
      }
    },
    selectedTime(){
      const time = this.format === '12' ? `${this.timeHours || '01'}:${this.timeMinutes || '00'} ${this.timeSeason || 'AM'}` : `${this.timeHours || '00'}:${this.timeMinutes || '00'}`

      this.timeUsed = formatTime({time, format: this.format === '12' ? "hh:mm A" : "HH:mm", toFormat: "HH:mm:ss"})
      this.returnTime()
    },

    resetTime(){
      this.timeHours = null
      this.timeMinutes = null
      this.timeSeason = null
    },

    validateTime(){
      if (Boolean(this.value)) {
        const validHour = this.hours.find(hour => hour.value == this.timeHours)
        // if (validHour) this.timeHours = this.hours.find(hour => !hour.disabled).value
        if (!validHour) this.timeHours = this.hours[0].value

        const validMinutes = this.minutes.find(min => min.value == this.timeMinutes)
        // if (!validMinutes) this.timeMinutes = this.minutes.find(min => !min.disabled).value
        if (!validMinutes) this.timeMinutes = this.minutes[0].value
      }
    },

    returnTime(){
      const finalTime = formatTime({time: this.timeUsed, format: this.format === '12' ? "hh:mm A" : "HH:mm", toFormat: "HH:mm:ss"})
      const payload = {
        finalTime,
        type: this.type,
        origin:this.origin
      }

      this.$emit('get-time', payload)
    }
  },
}
</script>

<style>
.white-background{
  background-color: white !important;
}
.maxSize{
  width: 22rem;
}
.append-time-button{
  padding: 0.5rem 0.75rem;
}
.time-cols{
  height: 10rem !important;
  padding: 0 0.5rem;
  overflow-x : auto;
}
.m-half{
  margin: 0.5rem;
}
.border-red{
  border: 1px solid #ea5455 !important;
  border-radius: 0.357rem;
}
.disabled-option{
  color: #b8c2cc;
}
</style>